import { Box, BoxProps, Text } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { withTranslations } from 'store/translations';

export interface OfficeInfoProps extends BoxProps {
  address: string;
  city: string;
  country: string;
  cvr: string;
  description: string | null;
  email: string;
  isMain?: boolean;
  officeName: string | null;
  phone: string;
}

const OfficeInfo = ({
  address,
  city,
  country,
  cvr,
  description,
  email,
  isMain = false,
  officeName,
  phone,
  ...boxProps
}: OfficeInfoProps) => {
  const { common } = withTranslations();
  const router = useRouter();

  return (
    <Box color='montain.base' {...boxProps}>
      <Text
        mb='xxs'
        textStyle={router.asPath == '/contact-us/polska' ? 'desktop.poland_office' : 'desktop.body.medium.s'}
      >
        {isMain ? common('headOffice') : ''}
      </Text>

      <Text
        as='h2'
        mb='xs'
        paddingTop={isMain ? '' : '20px'}
        textStyle={
          router.asPath == '/contact-us/polska'
            ? { base: 'mobile.h4', md: 'desktop.h4' }
            : { base: 'mobile.h4', md: 'desktop.h4' }
        }
      >
        {officeName || `${city}, ${country}`}
      </Text>
      {router.asPath == '/contact-us/polska' ? (
        <Box
          dangerouslySetInnerHTML={{
            __html: address,
          }}
          mb='xs'
          textStyle={{ base: 'desktop.poland_office', md: 'desktop.poland_office' }}
        />
      ) : (
        <Box
          dangerouslySetInnerHTML={{
            __html: address,
          }}
          mb='xs'
          textStyle={{ base: 'desktop.body.l', md: 'desktop.body.l' }}
        />
      )}

      <Box textStyle={{ base: 'desktop.body.medium.s', md: 'desktop.body.s' }}>
        {phone && <Text mb='3xs'>{phone}</Text>}
        {/* TODO: Local time is for now removed */}
        {/* <Text mb='3xs'>Local time: 10:17PM</Text> */}
        {email && <Text mb='xs'>{email}</Text>}
        {cvr && <Text>{cvr}</Text>}
      </Box>
      {!!description && (
        <Text
          mt='xs'
          textStyle='mobile.body.xs'
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        />
      )}
    </Box>
  );
};

export default OfficeInfo;
