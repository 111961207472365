import { Avatar, Box, ChakraProps, Image as ChakraImage } from '@chakra-ui/react';
import Image from 'next/image';
import { imageFormatter } from 'utils/imageFormatter';

export const ImageWrapper = ({
  imageFrontify,
  msImage,
  alt,
  imageSx = { w: '160px', h: '160px' },
  avatarSize = { base: 'xl', lg: '3xl' },
}: {
  imageFrontify?: string;
  msImage?: string;
  alt?: string;
  imageSx?: ChakraProps;
  avatarSize?: any;
}) => {
  const imageData = imageFormatter(imageFrontify, alt);
  return (
    <>
      {imageFrontify || msImage ? (
        <Box borderRadius='50%' overflow='hidden' mb='xs' order='1' {...imageSx}>
          {imageFrontify ? (
            <Image width={imageData.width} height={imageData.height} src={imageData.src} alt={imageData.alt} />
          ) : (
            msImage && <ChakraImage h='100%' src={'data:image/png;base64,' + msImage} alt={alt} />
          )}
        </Box>
      ) : (
        <Avatar name={alt} size={avatarSize} src={alt} alignSelf={{ base: 'center', md: 'start' }} {...imageSx} />
      )}
    </>
  );
};
