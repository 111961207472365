import React, { FunctionComponent } from 'react';
import { useCookies } from 'react-cookie';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import HubspotForm from 'react-hubspot-form';
import { withTranslations } from 'store/translations';

interface FormProps {
  portalId: string;
  formId: string;
  onSubmit?: () => void;
  onReady?: () => void;
}

export const Form: FunctionComponent<FormProps> = ({ portalId, formId, onSubmit, onReady }) => {
  const { common } = withTranslations();
  const [cookie] = useCookies(['nonce']);
  return (
    <HubspotForm
      portalId={portalId}
      formId={formId}
      loading={<div>{common('loadingForm')}...</div>}
      cssRequired=''
      onSubmit={onSubmit}
      onFormReady={() => {
        const script = document.createElement('script');
        script.nonce = cookie.nonce;
        script.src = 'https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js';
        const jqueryScript = document.getElementsByTagName('script');
        const src = Array.from(jqueryScript).filter((item) => item.src === script.src);
        if (src.length === 0) {
          document.body.appendChild(script);
        }
        if (onReady) {
          onReady();
        }
      }}
    />
  );
};
