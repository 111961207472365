import { HUBSPOT_TICKET_CONTACT_PERSON_EMAIL_FIELD } from 'lib/constants';
import React, { FunctionComponent } from 'react';

import { FormModal as FormModalModel } from '../../models/content-types/form_modal';
import { FormModal } from './FormModal';

interface ContactFormModalProps {
  model: FormModalModel;
  isOpen: boolean;
  onClose: () => void;
  contactPersonEmail?: string;
  id: string;
}

export const ContactFormModal: FunctionComponent<ContactFormModalProps> = ({
  model,
  isOpen,
  onClose,
  contactPersonEmail = '',
  id,
}) => {
  return (
    <FormModal
      id={id}
      model={model}
      isOpen={isOpen}
      onClose={onClose}
      onFormReady={() => {
        const fields = document.querySelectorAll('input');
        fields.forEach((field) => {
          const inputField = field as HTMLInputElement;
          if (inputField.name === HUBSPOT_TICKET_CONTACT_PERSON_EMAIL_FIELD) {
            inputField.value = contactPersonEmail;
          }
        });
      }}
    />
  );
};
