import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  VisuallyHidden,
} from '@chakra-ui/react';
import { Button } from 'components/base/Button';
import React, { FunctionComponent, useState } from 'react';
import { withTranslations } from 'store/translations';
import { imageFormatter } from 'utils/imageFormatter';
import { FormModalData } from 'utils/types/formData';

import { FormModal as FormModalModel } from '../../models/content-types/form_modal';
import { FormForModal } from './FormForModal';

interface ModalProps {
  model: FormModalModel;
  isOpen: boolean;
  onClose: () => void;
  onFormReady?: () => void;
  id: string;
}

export const FormModal: FunctionComponent<ModalProps> = ({ model, isOpen, onClose, onFormReady, id }) => {
  const { aria, common } = withTranslations();

  const [shouldShowCloseBtn, setShouldShowCloseBtn] = useState<boolean>(false);
  const [hasBeenSubmitted, setHasBeenSubmitted] = useState<boolean>(false);

  const formDataObj = JSON.parse(model.elements.hubspotFormSnippetForm.value);
  const headline = model.elements.headline.value;
  const imgAltText = model.elements.altTextImage?.value;
  const compressimage = model.elements.compressImage.value ? model.elements.compressImage.value : 75;

  const imageData = imageFormatter(model.elements.imageFrontify?.value, imgAltText, false, compressimage);
  const thankYouImageAltText = model.elements.altTextThankyouImage?.value;
  const thankYouImageData = imageFormatter(model.elements.thankYouImageFrontify.value, thankYouImageAltText);
  const data = {
    formId: formDataObj.FormGUID,
    portalId: formDataObj.PortalID,
    headline: headline !== '' ? headline : undefined,
    imageData: imageData,
    thankYouImage: thankYouImageData,
    submittedMessageAlignment: 'center',
    fieldColor: 'gray.98',
  } as FormModalData;

  return (
    <Modal
      variant={shouldShowCloseBtn ? 'withoutCloseIcon' : 'withCloseIcon'}
      isOpen={isOpen}
      motionPreset='slideInBottom'
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent maxW={hasBeenSubmitted ? '704px' : '1194px'}>
        <ModalCloseButton aria-label={aria('closeFormModal')} aria-controls={model.system.name} />
        <ModalBody id={model.system.name} mt={{ base: 's', md: '0' }}>
          <FormForModal
            id={id}
            data={data}
            onFormSubmit={() => {
              setHasBeenSubmitted(true);
              setShouldShowCloseBtn(true);
            }}
            onFormReady={onFormReady}
          />
        </ModalBody>
        {shouldShowCloseBtn && (
          <ModalFooter w='fit-content' p='0' m='auto' mb='s'>
            <Button
              ariaControls={model.system.name}
              size='medium'
              onClick={() => {
                onClose();
                setShouldShowCloseBtn(false);
              }}
            >
              {common('close')}
              <VisuallyHidden>{aria('formModal')}</VisuallyHidden>
            </Button>
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};
