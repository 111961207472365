import { UrlObject } from 'node:url';

import { Box, Button as ChakraButton } from '@chakra-ui/react';
import React, { FunctionComponent, ReactElement, ReactNode } from 'react';

import { IconObject } from '../../lib/Icons';
import { IconComp } from '../base/IconComp';

interface ButtonProps {
  isDisabled?: boolean;
  size: string;
  children: ReactNode;
  width?: string;
  iconName?: keyof typeof IconObject;
  onClick?: () => void;
  as?: any;
  href?: UrlObject | string;
  ariaLabel?: string;
  ariaControls?: string;
  target?: string;
}

export const Button: FunctionComponent<ButtonProps> = ({
  onClick,
  isDisabled,
  size,
  width,
  children,
  iconName,
  as = 'button',
  href,
  ariaLabel,
  ariaControls,
  target,
}): ReactElement => {
  return (
    <ChakraButton
      id='chakra-button-base'
      w={width || 'auto'}
      as={as}
      href={href}
      textStyle={'desktop.button'}
      fontWeight='unset'
      textAlign={'center'}
      size={{ base: size === 'medium' ? size : 'mobile', md: size }}
      leftIcon={iconName && getIcon(iconName, isDisabled)}
      isDisabled={isDisabled}
      aria-label={ariaLabel}
      aria-controls={ariaControls}
      target={target}
      whiteSpace={'normal'}
      borderRadius='4px'
      onClick={onClick}
    >
      {children}
    </ChakraButton>
  );
};

const getIcon = (iconName: keyof typeof IconObject, isDisabled?: boolean): React.ReactElement => {
  return <IconComp iconName={iconName} styleName={isDisabled ? 'disabled' : 'default'} />;
};

export const getButtonWithLinkStyling = (
  ariaLabel: string,
  text: string,
  onClick?: () => void,
  ariaControls?: string,
  disabled?: boolean,
) => {
  return (
    <ChakraButton
      data-group
      aria-label={ariaLabel}
      aria-controls={ariaControls}
      variant='secondary'
      colorScheme={'cyan.web'}
      iconSpacing='0px'
      textStyle={'desktop.button'}
      textAlign={'center'}
      fontWeight='unset'
      disabled={disabled}
      leftIcon={
        <Box transition='all .5s ease-in-out' pr='xxs' _groupHover={{ pr: '0', pl: 'xxs' }}>
          <IconComp
            iconName='chevronRightForLink'
            chakraStyle={{
              pr: '3xs',
              path: {
                stroke: disabled ? 'mountain.60' : 'cyan.web',
              },
            }}
          />
        </Box>
      }
      onClick={onClick}
    >
      {text}
    </ChakraButton>
  );
};
