export const GOOGLE_MAP_POSITION = Object.freeze({
  lat: 36,
  lng: 9,
});
export const GOOGLE_MAP_ZOOM = 4;

export const HUBSPOT_TICKET_CONVERSION_PAGE_FIELD = 'TICKET.conversion_page';
export const HUBSPOT_TICKET_CONTACT_PERSON_EMAIL_FIELD = 'TICKET.contact_person_email';

export const ISR_REVALIDATE_TIME = process.env.NEXT_PUBLIC_APP_ENV === 'developement' ? 0 : 1 * 60; // changed from 10 minutes to 1 minute for prod, instant for development

export const META_TITLE_POSTFIX = ' - Ramboll Group';
export const META_OG_TYPE = 'article';
export const META_OG_SITENAME = 'Ramboll_Group';
export const META_TWITTER_CARD = 'summary';

export const PAGINATION_LIMIT = 12;
export const PAGINATION_LIST_LIMIT = 10;

export const DEV_DEPLOYED_HOSTNAME = 'Ramboll.com'; //ramcmswebsiteprod.azurewebsites.net
