import { Contact, ContentPage, Office, PDFEditorialSpotItem } from 'models';
import slugify from 'slugify';
import { Translations } from 'store/translations';

export const getLinkToMore = (
  category: string,
  model: ContentPage | Contact | Office | PDFEditorialSpotItem,
  translations: Translations,
) => {
  let url = '';
  if (!model || model.system.type === 'pdf_editorial_spot_item') return url;
  switch (category) {
    case 'sector':
      url = `/${slugify(translations.sector((model as ContentPage).elements.tagsSector.value[0]?.codename), {
        lower: true,
      })}`;
      break;
    case 'project':
      url = `/${translations.multiple(category)}/${slugify(
        translations.sector((model as ContentPage).elements.tagsSector.value[0]?.codename),
        { lower: true },
      )}`;
      break;
    case 'insight':
      url = `/${translations.multiple(category)}/${slugify(
        translations.theme_topic((model as ContentPage).elements.tagsThemeTopic.value[0]?.codename),
        { lower: true },
      )}`;
      break;
    case 'product':
      url = `/${translations.multiple(category)}/${slugify(
        translations.sector((model as ContentPage).elements.tagsSector.value[0]?.codename),
        { lower: true },
      )}`;
      break;
    case 'news':
      url = `/${translations.multiple(category)}`;
      break;
    case 'service_line':
      url = `/${slugify(translations.service_line((model as ContentPage).elements.tagsServiceLine.value[0]?.codename), {
        lower: true,
      })}`;
      break;
    case 'office':
      url = `/${translations.multiple('contactUsUrl')}`;
      break;
    case 'people':
      url = `/${translations.multiple('contactUsUrl')}/${translations.url('peopleUrl')}`;
      break;
    default:
      url = '/';
      break;
  }
  return url.replaceAll(/\/+/g, '/').replaceAll('undefined', '');
};
