import { AspectRatio, Box, Flex, Text } from '@chakra-ui/react';
import Image from 'next/image';
import React, { FunctionComponent, useState } from 'react';
import { FormModalData } from 'utils/types/formData';

import { FormWrapper } from './FormWrapper';

interface FormProps {
  data: FormModalData;
  onFormSubmit?: () => void;
  onFormReady?: () => void;
  id: string;
}

export const FormForModal: FunctionComponent<FormProps> = ({ data, onFormSubmit, onFormReady, id }) => {
  const [hasBeenSubmitted, setHasBeenSubmitted] = useState<boolean>(false);

  return (
    <Flex
      id={id}
      flexDir={{ base: 'column', lg: hasBeenSubmitted ? 'column' : 'row' }}
      justifyContent='center'
      bg='white'
      gap='s'
    >
      {hasBeenSubmitted && (
        <Box mt='l' w='fit-content' mx='auto'>
          <Image
            width={60}
            height={70}
            src={data.thankYouImage.src}
            alt={data.thankYouImage.alt}
            style={{ objectFit: 'cover' }}
          />
        </Box>
      )}
      {!hasBeenSubmitted && (
        <Box
          bg={{ base: 'white', lg: 'cyan.10' }}
          w={{ base: '100%', lg: '42.8%' }}
          h={{ base: 'fit-content', lg: 'inherit' }}
          mt={{ base: 's', lg: '0' }}
          display='flex'
          flexDir={'column'}
          justifyContent={'space-between'}
        >
          {data.headline && (
            <Text
              mt={{ base: '0px', lg: 'l' }}
              mb={{ base: 'xs', lg: '0px' }}
              ml={{ base: '0px', lg: 's' }}
              as='h2'
              textAlign={{ base: 'center', lg: 'start' }}
              w={{ base: '100%', lg: '58.97%' }}
              wordBreak='break-word'
              color='cyan.web'
              textStyle={{ base: 'mobile.h4', lg: 'desktop.h4' }}
              h='fit-content'
            >
              {data.headline}
            </Text>
          )}
          <AspectRatio ratio={4 / 3}>
            <Image
              width={data.imageData.width}
              height={data.imageData.height}
              src={data.imageData.src}
              alt={data.imageData.alt}
              quality={data.imageData.quality}
              style={{ objectFit: 'cover' }}
            />
          </AspectRatio>
        </Box>
      )}

      <Box
        pr={{ base: '0', lg: 'xs' }}
        mr={{ base: 'xs', lg: 'm' }}
        ml={{ base: 'xs', lg: hasBeenSubmitted ? 'm' : '0' }}
        mt={{ base: '0', lg: hasBeenSubmitted ? '0' : 'l' }}
        mb='s'
        pb={'3xs'}
        maxH={{ base: 'fit-content', lg: '480px' }}
        w={{ base: '', lg: hasBeenSubmitted ? '420px' : '57%' }}
        alignSelf={hasBeenSubmitted ? 'center' : ''}
        overflowY={hasBeenSubmitted ? 'hidden' : 'auto'}
        sx={{
          '::-webkit-scrollbar': {
            bg: 'mountain.40',
            width: '3px',
            height: '3px',
          },
          '::-webkit-scrollbar-thumb': {
            bg: 'cyan.web',
          },
          '::-webkit-scrollbar-track': {
            width: '3px',
            height: '3px',
          },
        }}
      >
        <FormWrapper
          data={data}
          onReady={() => {
            if (onFormReady) {
              onFormReady();
            }
          }}
          onSubmit={() => {
            setHasBeenSubmitted(true);
            if (onFormSubmit) {
              onFormSubmit();
            }
          }}
        />
      </Box>
    </Flex>
  );
};
