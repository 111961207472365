import { Box } from '@chakra-ui/react';
import { HUBSPOT_TICKET_CONVERSION_PAGE_FIELD } from 'lib/constants';
import React, { FunctionComponent, useState } from 'react';
import { primary } from 'themes/components/button';
import {
  FormHeroWithAssetData,
  FormHeroWithTextData,
  FormModalData,
  InbuiltFormData,
  StandaloneFormData,
} from 'utils/types/formData';

import { Form } from './Form';

interface FormWrapperProps {
  data: FormHeroWithAssetData | FormModalData | FormHeroWithTextData | StandaloneFormData | InbuiltFormData;
  onSubmit?: () => void;
  onReady?: () => void;
  style?: any;
}

export const FormWrapper: FunctionComponent<FormWrapperProps> = ({ data, onSubmit, onReady, style }) => {
  const [hasBeenSubmitted, setHasBeenSubmitted] = useState<boolean>(false);

  return (
    <Box
      {...style}
      color='mountain'
      sx={{
        form: {
          display: hasBeenSubmitted ? 'none' : 'flex',
          flexDir: 'column',
          gap: '15px',
        },
        'form fieldset': {
          display: 'flex',
          flexDir: { base: 'column', md: 'row' },
          gap: '25px',
        },
        'form fieldset div': {
          display: 'flex',
          flexDir: 'column',
        },
        'form fieldset div label': {
          mb: 'xxs',
        },
        'div ul': {
          listStyleType: 'none',
        },
        'label span': {
          textStyle: 'mobile.body.xs',
        },
        'div p span': {
          textStyle: 'mobile.body.xs',
          fontSize: '0.7rem',
          lineHeight: '16px',
        },
        'h1, h2, h3, h4, h5, h6': {
          textStyle: 'desktop.body.l',
        },
        hr: {
          mb: '3xs',
          mt: 'xs',
        },
        'input:-webkit-autofill': {
          WebkitBoxShadow: '0 0 0 100px #F8F8F8 inset',
        },
        'input, textarea, select': {
          textStyle: 'mobile.body.s',
          p: 'xxs',
          bg: data.fieldColor,
          borderWidth: '1px',
          borderColor: 'mountain.20',
          _placeholder: {
            color: 'mountain.60',
          },
          _focus: {
            borderColor: 'cyan.web',
            borderWidth: '2px',
            outline: 'none',
          },
          _hover: {
            outline: 'none',
            _placeholder: {
              color: 'mountain.base',
            },
          },
        },
        'form fieldset div, select, input, textarea': {
          w: '100%',
        },
        textarea: {
          h: '100px',
        },
        'input[type=submit]': {
          w: { base: '100%', md: 'fit-content' },
          ...primary,
          color: 'white',
          borderWidth: '0px',
          _hover: {
            bg: 'cyan.a11y',
          },
          curspor: 'pointer',
          fontFamily: 'HCo Gotham Rounded Medium',
          fontStyle: 'normal',
          fontWeight: 350,
          fontSize: '18px',
          lineHeight: '20px',
          borderRadius: '4px',
          p: '20px',
        },
        a: {
          color: 'cyan.web',
          _hover: {
            color: 'cyan.a11y',
            textDecor: 'underline',
          },
          _visited: {
            color: 'cyan.a11y',
          },
          _focus: {
            p: '3xs',
            borderColor: 'cyan.web',
            borderWidth: '1px',
            outline: 'none',
          },
        },
        div: {
          h: '100%',
        },
        '.submitted-message': {
          display: 'flex',
          flexDir: 'column',
          gap: 'xs',
          textAlign: data.submittedMessageAlignment,
          w: '100%',
          color: 'thankYouBackgroundImage' in data && data.thankYouBackgroundImage ? 'white' : '',
        },
        '.submitted-message p:first-of-type': {
          textStyle: 'desktop.h4',
        },
        '.submitted-message p:first-of-type span': {
          textStyle: 'desktop.h4',
          color: 'thankYouBackgroundImage' in data && data.thankYouBackgroundImage ? 'white !important' : '',
        },
        '.submitted-message a': {
          p: 'xs',
          color: 'white',
          ...primary,
          fontWeight: 'bold',
        },
        '.submitted-message p': {
          minH: '60px',
        },
        'select::-ms-expand': {
          display: 'none',
        },
        select: {
          WebkitAppearance: 'none',
          MozAppearance: 'none',
          appearance: 'none',
          backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath stroke='%23273943' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='m6 9 6 6 6-6'/%3E%3C/svg%3E")`,
          backgroundRepeat: 'no-repeat',
          backgroundPositionX: '98%',
          backgroundPositionY: '10px',
        },
        '.hs-error-msgs': {
          mt: '5px',
          color: 'field',
          fontFamily: 'HCo Gotham Rounded Book',
          fontStyle: 'normal',
          fontWeight: 325,
          fontSize: '12px',
          lineHeight: '14px',
        },
        '.hs-error-msg, .hs-error-msgs': {
          color: 'field',
          fontFamily: 'HCo Gotham Rounded Book',
          fontStyle: 'normal',
          fontWeight: 325,
          fontSize: '12px',
          lineHeight: '14px',
        },
        '.error': {
          borderColor: 'field',
          borderWidth: '2px',
        },
        '.legal-consent-container': {
          display: 'flex',
          flexDirection: 'column',
          gap: 'xs',

          fontSize: '12px',
          color: 'mountain.80',
          fontFamily: 'HCo Gotham Rounded Book',
        },
        '.hs-form-booleancheckbox-display': {
          display: 'flex',
          flexDirection: 'row',
          gap: 'xxs',
          alignItems: 'center',
          _focusWithin: {
            borderColor: 'cyan.a11y',
            borderWidth: '2px',
            p: '3xs',
          },
        },
        '.hs-form-booleancheckbox-display input': {
          w: '20px',
          h: '20px',
        },
        '.hs-form-booleancheckbox label span': {
          fontSize: '14px',
          color: 'mountain.base',
          fontFamily: 'HCo Gotham Rounded Book',
          display: 'flex',
          flexDirection: 'row',
        },
      }}
    >
      <Form
        portalId={data.portalId}
        formId={data.formId}
        onReady={() => {
          const fields = document.querySelectorAll('input');
          fields.forEach((field) => {
            const inputField = field as HTMLInputElement;
            if (inputField.name === HUBSPOT_TICKET_CONVERSION_PAGE_FIELD) {
              inputField.value = window.location.href;
            }
          });

          if (onReady) {
            onReady();
          }
        }}
        onSubmit={() => {
          if (onSubmit) {
            onSubmit();
          }
          setHasBeenSubmitted(true);
        }}
      />
    </Box>
  );
};
