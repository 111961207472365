import { Grid, GridItem, Text, useDisclosure } from '@chakra-ui/react';
import { getButtonWithLinkStyling } from 'components/base/Button';
import { ImageWrapper } from 'components/wrapper/imageWrapper';
import { Contact as ContactModel, FormModal as FormModalModel } from 'models';
import { FunctionComponent } from 'react';
import { withTranslations } from 'store/translations';

import { ContactFormModal } from './ContactFormModal';

interface PersonOverviewItemProps {
  contact: ContactModel;
  formModalButtonText?: string;
  formModal?: FormModalModel;
  editorialSpotsId: string;
  showCountry?: string;
}

export const PersonOverviewItem: FunctionComponent<PersonOverviewItemProps> = ({
  contact,
  formModalButtonText,
  formModal,
  editorialSpotsId,
  showCountry,
}) => {
  const { aria } = withTranslations();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const displayName = contact.elements?.displayName.value;
  const jobTitle = contact.elements?.jobTitle.value;
  const phoneNumber = contact.elements?.phoneNumber.value;
  const email = contact.elements?.email.value;
  const frontifyImageData = contact.elements?.frontifyImage?.value;
  const msImage = contact.elements?.msImage.value;
  const country = contact.elements?.country.value;
  return (
    <Grid color='mountain.base' gridTemplateRows={`auto 2fr auto auto`} h='100%'>
      <GridItem>
        <ImageWrapper
          alt={displayName}
          imageFrontify={frontifyImageData}
          msImage={msImage}
          imageSx={{ w: { base: '160px', lg: '205px' }, h: { base: '160px', lg: '205px' } }}
          avatarSize={{ base: '3xl', lg: '5xl' }}
        />
      </GridItem>

      <GridItem mt={{ base: 'xs', lg: 's' }}>
        {displayName !== '' && (
          <Text textStyle='desktop.body.medium.l' fontWeight='bold'>
            {displayName}
          </Text>
        )}
        {jobTitle != '' && <Text textStyle='desktop.body.book.l'>{jobTitle}</Text>}
      </GridItem>

      {phoneNumber != '' && (
        <GridItem mt='xs'>
          <Text textStyle='mobile.body.s'>{phoneNumber}</Text>
        </GridItem>
      )}
      {showCountry == 'yes' && country != '' && (
        <GridItem mt='xs'>
          <Text textStyle='mobile.body.s'>{country}</Text>
        </GridItem>
      )}
      {/* TODO: add current time when api is updated to return city timezone */}
      {formModalButtonText && formModal && (
        <GridItem mt={{ base: 'xs', lg: 'xxs' }}>
          {getButtonWithLinkStyling(
            `${aria('openContactGetInTouchWith')} ${displayName}`,
            formModalButtonText,
            onOpen,
            formModal.system.name,
          )}
          <ContactFormModal
            id={`${editorialSpotsId}${formModal.system.id}${contact.system.id}`}
            model={formModal}
            isOpen={isOpen}
            contactPersonEmail={email}
            onClose={onClose}
          />
        </GridItem>
      )}
    </Grid>
  );
};
